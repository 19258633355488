import styled from 'styled-components';
import skapaToken from '@ingka/variables';

export const contentDelimiterStyles = `
  max-width: 1024px;
  margin: 0 auto;
  padding: 16px;
`;

export const appHeaderHeight = '108px';

export const appFooterHeight = '168px';

export const Page = styled.div`
  min-height: calc(100vh - ${appFooterHeight} - ${appHeaderHeight});
  ${contentDelimiterStyles};
`;

export const PageContainer = styled.div`
  background-color: ${skapaToken.colourNeutralGrey100};
`;

export const PageTitle = styled.h2`
  text-align: center;
`;

export const Stack = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  gap: 1rem;
`;

export const StackSpaced = styled(Stack)`
  justify-content: space-between;
  flex-grow: 1;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  flex-wrap: wrap;
`;

export const RowSpaced = styled(Row)`
  justify-content: space-between;
  flex-grow: 1;
`;
