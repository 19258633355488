import Button from '@ingka/button';
import { Select, Option } from '@ingka/select';
import { useTranslate } from 'hooks/useTranslate';
import { useNCAControlsActionSelect } from '../../../../hooks/useNCAControlsActionSelect';
import { ClaimControlOptions } from '../../NCAControls.types';
import { Confirmation } from 'components/common/Confirmation/Confirmation';
import styled from 'styled-components';

const ButtonFullWidth = styled(Button)`
  width: 100%;
`;

export function NCAControlsActionSelect({
  onSubmit,
  isClaimApprovable,
  isClaimRejectable,
  isClaimSendableToLDM,
}: ClaimControlOptions) {
  const t = useTranslate();

  const {
    action,
    actions,
    handleChangeAction,
    handleSubmit,
    canSubmit,
    reasonCode,
    setReasonCode,
    reasonCodes,
    showReasonCodes,
  } = useNCAControlsActionSelect(
    isClaimApprovable,
    isClaimRejectable,
    isClaimSendableToLDM,
    onSubmit
  );

  return (
    <>
      {/* Select: Action */}
      <Select
        id="NCAControlsActionSelectId"
        data-testid="NCAControlsActionSelectId"
        label={t('selectAction')}
        multiple={false}
        value={action}
        onChange={(e) => handleChangeAction(e.target.value)}
      >
        {/* <Option id="action-noValue" name="-" value={''} /> */}
        {actions.map((action) => (
          <Option
            key={'nca-controls-action-' + action.key}
            id={action.key}
            name={t(action.key)}
            value={action.key}
            disabled={action.disabled}
          />
        ))}
      </Select>

      {/* Select: Reason Code */}
      {true && (
        <Select
          id="NCAControlsReasonCodeSelectId"
          data-testid="NCAControlsReasonCodeSelectId"
          label={t('reasonCode')}
          multiple={false}
          value={reasonCode}
          onChange={(e) => setReasonCode(e.target.value)}
          disabled={!showReasonCodes}
        >
          {/* <Option id="reasonCode-noValue" name="-" value={''} /> */}
          {reasonCodes.map((reasonCode) => (
            <Option
              key={'reasonCode-' + reasonCode.code}
              id={reasonCode.code}
              name={reasonCode.message}
              value={reasonCode.code}
            />
          ))}
        </Select>
      )}

      {/* Submit changes */}
      <Confirmation
        handleOnConfirmed={handleSubmit}
        triggerElement={
          <ButtonFullWidth type="emphasised" disabled={!canSubmit}>
            {t('saveAndSubmit')}
          </ButtonFullWidth>
        }
        disabled={!canSubmit}
      />
    </>
  );
}
