import { GetOneNCAResponse } from 'api/genTypes/dto';
import { ncaAPI } from 'api/ncaAPI';
import { queryClient } from 'components/App';
import { useNcaDetails } from 'pages/nca/components/NCADetails/hooks/useNcaDetails';
import { useState } from 'react';

export function useNCAControls(nca: GetOneNCAResponse) {
  const { refetch } = useNcaDetails();
  const [isSuccessNotificationVisible, setIsSuccessNotificationVisible] = useState<boolean>(false);
  const [isFailNotificationVisible, setIsFailNotificationVisible] = useState<boolean>(false);

  const handleOnSubmit = (action: string, reasonCode?: string) => {
    ncaAPI
      .post({
        nonConformityAdviceId: nca.nonConformityAdviceId,
        requested_action: action,
        reasonCode: reasonCode,
      })
      .then(() => {
        setIsSuccessNotificationVisible(true);
        setIsFailNotificationVisible(false);
      })
      .catch(() => {
        setIsSuccessNotificationVisible(false);
        setIsFailNotificationVisible(true);
      })
      .finally(() => {
        // Reload the current NCADetails page
        refetch();

        // Invalidate the NCAList query, so that the next time we open NCAList,
        // we re-load the list with the latest info.
        queryClient.invalidateQueries({ queryKey: 'NCA_OVERVIEW_QUERY' });
      });
  };

  return {
    handleOnSubmit,
    isSuccessNotificationVisible,
    setIsSuccessNotificationVisible,
    isFailNotificationVisible,
    setIsFailNotificationVisible,
  };
}
