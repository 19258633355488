import Toast from '@ingka/toast';
import { UnexpectedError } from 'components/alerts';
import styled from 'styled-components';
import { useNCAControls } from './hooks/useNCAControls';
import { NCAControlsView } from './components/NCAControlsView/NCAControlsView';
import { NCAControlsProps } from './NCAControls.types';
import { useTranslate } from 'hooks/useTranslate';

export const NCAControlsLayout = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: 100%;
  flex-grow: 1;
  align-items: stretch;
  gap: 1rem;
`;

export function NCAControls({ nca }: NCAControlsProps) {
  const {
    handleOnSubmit,
    isSuccessNotificationVisible,
    setIsSuccessNotificationVisible,
    isFailNotificationVisible,
    setIsFailNotificationVisible,
  } = useNCAControls(nca);
  const t = useTranslate();

  if (!nca) {
    return <UnexpectedError />;
  }

  return (
    <>
      <NCAControlsView
        nca={nca}
        isClaimApprovable={nca.isAcceptActionAllowed}
        isClaimRejectable={nca.isRejectActionAllowed}
        isClaimSendableToLDM={nca.isSendToLdmActionAllowed}
        onSubmit={handleOnSubmit}
      />

      {/* Notifications */}

      {/* Success */}
      <Toast
        text={t('claimUpdated')}
        isOpen={isSuccessNotificationVisible}
        onCloseRequest={() => {
          setIsSuccessNotificationVisible(false);
        }}
        ariaLabelCloseBtn={t('dismissNotification')}
      />

      {/* Failure */}
      <Toast
        text={t('claimUpdateFailed')}
        isOpen={isFailNotificationVisible}
        onCloseRequest={() => {
          setIsFailNotificationVisible(false);
        }}
        ariaLabelCloseBtn={t('dismissNotification')}
      />
    </>
  );
}
