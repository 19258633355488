import { useEffect, useState } from 'react';
import { NcaActions } from '../constants/ncaActions';
import { ConfigurationRejectionReasonCode } from 'hooks/useConfigurations.types';
import { useRejectionReasonCodes } from 'hooks/useConfigurations';

export function useNCAControlsActionSelect(
  isClaimApprovable: boolean,
  isClaimRejectable: boolean,
  isClaimSendableToLDM: boolean,
  onSubmit?: (action: string, reasonCode?: string) => void
) {
  const reasonCodes: ConfigurationRejectionReasonCode[] = useRejectionReasonCodes();
  const [action, setAction] = useState<string | undefined>('');
  const [reasonCode, setReasonCode] = useState<string | undefined>('');

  // Whenever we change the action type to something other than Reject,
  // we clear the reason code (since reason code is only relevant for reject)
  useEffect(() => {
    if (action !== NcaActions.Reject) {
      setReasonCode('');
    }
  }, [setReasonCode, action]);

  const actions: {
    key: string;
    disabled?: boolean;
  }[] = [
    {
      key: NcaActions.Approve,
      disabled: !isClaimApprovable,
    },
    {
      key: NcaActions.Reject,
      disabled: !isClaimRejectable,
    },
    {
      key: NcaActions.SendToManagerApproval,
      disabled: !isClaimSendableToLDM,
    },
  ];

  const handleChangeAction = (selected: string) => {
    if (!selected) {
      setAction(undefined);
    }

    setAction(selected);
  };

  const handleSubmit = () => {
    if (onSubmit) {
      onSubmit(action, reasonCode);
      setReasonCode('');
      setAction('');
    }
  };

  const showReasonCodes = action === NcaActions.Reject;
  const isActionValid: boolean = !!action;
  const isReasonCodeNeeded: boolean = action === NcaActions.Reject;
  const isReasonCodeFilledOut: boolean = !!reasonCode;
  const isReasonCodeValid: boolean = !isReasonCodeNeeded || isReasonCodeFilledOut;
  const canSubmit: boolean = isActionValid && isReasonCodeValid;

  return {
    action,
    actions,
    handleChangeAction,
    handleSubmit,
    canSubmit,
    reasonCode,
    setReasonCode,
    reasonCodes,
    showReasonCodes,
  };
}
