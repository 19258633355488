import { NCAControlsActionSelect } from '../NCAControlsActionSelect/NCAControlsActionSelect';
import { NCAControlsLog } from '../NCAControlsLog/NCAControlsLog';
import { NCAControlsViewProps } from './NCAControlsView.types';
import { RowSpaced, StackSpaced } from 'components/layout';

export function NCAControlsView({
  onSubmit,
  isClaimApprovable,
  isClaimRejectable,
  isClaimSendableToLDM,
}: NCAControlsViewProps) {
  return (
    <RowSpaced>
      {/* LOG */}
      <StackSpaced>
        <NCAControlsLog />
      </StackSpaced>

      <RowSpaced>
        {/* COMMENT */}
        <StackSpaced></StackSpaced>

        {/* ACTION */}
        <StackSpaced>
          <NCAControlsActionSelect
            isClaimApprovable={isClaimApprovable}
            isClaimRejectable={isClaimRejectable}
            isClaimSendableToLDM={isClaimSendableToLDM}
            onSubmit={onSubmit}
          />
        </StackSpaced>
      </RowSpaced>
    </RowSpaced>
  );
}
