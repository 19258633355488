import Table, { TableHeader, TableBody } from '@ingka/table';
import { DataTableEntity, DataTableProps } from './DataTable.types';
import { DataTableCell } from './components/DataTableCell/DataTableCell';
import { DataContentWrapper } from 'components/common/DataWrapper';
import styled from 'styled-components';
import { DataTableColumnHeader } from './components/DataTableColumnHeader/DataTableColumnHeader';

const Th = styled.th`
  font-weight: bold;
  white-space: nowrap;
`;
const Td = styled.td`
  white-space: nowrap;
`;

const TdInner = styled.div`
  padding-left: 1rem;
`;

const Tr = styled.tr`
  margin: 0 1rem;
`;

/** DataTable represents a generic data table which renders
 * the provided data in the browser according to the columns provided. */
export function DataTable<T extends DataTableEntity>({
  columns,
  data,
  isLoading,
  error,
  idField,
  withFiltering,
  withSorting,
}: DataTableProps<T>) {
  /** Only render table rows if:
   *
   * - We have data
   * - We are not currently loading data
   * - There are no errors
   */
  const shouldRenderTableRows: boolean = data && !isLoading && !error;

  // In case some data entries are undefined, we filter those away first.
  const filteredData: T[] = data?.filter((d) => d);

  return (
    <>
      <Table fullWidth>
        {/* Table Header */}
        <TableHeader>
          <Tr>
            {columns.map((c) => (
              <Th key={`data-table-header-column-${c.columnId}`}>
                <DataTableColumnHeader
                  column={c}
                  withFiltering={withFiltering}
                  withSorting={withSorting}
                />
              </Th>
            ))}
          </Tr>
        </TableHeader>

        {/* Table Body */}
        <TableBody>
          {/* Only render the data depending on this condition. If false, we render no rows. */}
          {shouldRenderTableRows &&
            filteredData.map((dataItem: T, index: number) => (
              // Render each row of the data
              <Tr key={`data-table-row-${idField ? dataItem[idField] : index}`}>
                {/* For each row, render the corresponding field values as columns */}
                {columns.map((c) => (
                  <Td
                    key={`data-table-row-${idField ? dataItem[idField] : index}-column-${
                      c.columnId
                    }`}
                  >
                    <TdInner>
                      <DataTableCell<T> column={c} dataItem={dataItem} />
                    </TdInner>
                  </Td>
                ))}
              </Tr>
            ))}
        </TableBody>
      </Table>

      {/* Render loading, data, and error alerts outside the table body to ensure better formatting */}
      <DataContentWrapper data={filteredData} isLoading={isLoading} error={error} />
    </>
  );
}
